import React from 'react';
import '../Aboutus/Gallery.css';

const Gallery = () => {
  const images = [
    { id: 1, src: 'g1.jpg', title: 'Matar Paneer' },
    { id: 2, src: 'g2.jpg', title: 'Kadai Chicken' },
    { id: 3, src: 'g3.jpg', title: 'Veg Cutlet' },
    { id: 4, src: 'g4.jpg', title: 'Chicken Tikka' },
    { id: 5, src: 'g5.jpg', title: 'Rui Kalia Macher Jhal' },
  ];

  return (
    <div className="gallery-section">
      <h5>Food Item</h5>
      <h2>Our Food Gallery</h2>
    
      <div className="gallery-grid">
        <div className="gallery-item long">
          <img src={images[0].src} alt={images[0].title} />
          <div className="overlay11">
            <h1>{images[0].title}</h1>
          </div>
        </div>
        <div className="gallery-small-grid">
          {images.slice(1).map((image) => (
            <div className="gallery-item" key={image.id}>
              <img src={image.src} alt={image.title} />
              <div className="overlay11">
                <h1>{image.title}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
