import React, { useState } from 'react';
import { FaFish, FaDrumstickBite, FaCarrot, FaEgg, FaUtensils } from 'react-icons/fa'; 
import { MdDinnerDining } from "react-icons/md";
import { GiChickenOven } from "react-icons/gi";
import Swal from 'sweetalert2';
import '../Aboutus/Menu.css'; 

const Menu = () => {
  const [activeCategory, setActiveCategory] = useState('Bengali Thali');

  const menuData = {
    categories: [
      { id: 1, name: 'Bengali Thali', icon: <FaUtensils className='c-icon'/> },
      { id: 2, name: 'Vegetarian & Snacks', icon: <FaCarrot className='c-icon'/> },
      { id: 3, name: 'Fish Dishes', icon: <FaFish className='c-icon'/> },
      { id: 4, name: 'Mutton Dishes', icon: <GiChickenOven className='c-icon'/> },
      { id: 5, name: 'Chicken Dishes', icon: <FaDrumstickBite className='c-icon'/> },
     
      { id: 6, name: 'Egg Dishes', icon: <FaEgg className='c-icon'/> },
    ],
    items: [
      // Bengali Thali (4 items)
      {
        id: 1,
        category: 'Bengali Thali',
        name: 'Mutton Thali',
        description: '2 pcs (Mutton + 500gm Rice), 2 Rotis, Dal, Salad,Sabzi, Sweet, Raita, Chutney',
        price: '₹399.00',
        extras: '',
        image: 'mutton thali.jpeg',
      },
      {
        id: 2,
        category: 'Bengali Thali',
        name: 'Chicken Thali',
        description: '2 pcs (Chicken), Rice, 2 Rotis, Dal,Sabzi, Salad, Sweet Raita, Chutney',
        price: '₹299.00',
        extras: '',
        image: 'chichen thali.webp',
      },
      {
        id: 3,
        category: 'Bengali Thali',
        name: 'Fish Thali',
        description: '2 pcs (Fish), Rice, 2 Rotis, Dal,Sabzi, Salad, Sweet Raita, Chutney',
        price: '₹299.00',
        extras: '',
        image: 'fish thali.jpg',
      },
      

      // Fish Dishes (3 items)
      {
        id: 4,
        category: 'Fish Dishes',
        name: 'Rui Kalia Macher Jhal',
        description: '2 pcs (1 Plate)',
        price: '₹325.00',
        extras: 'With Side Salad',
        image: 'Rui Katla.jpeg',
      },
      {
        id: 5,
        category: 'Fish Dishes',
        name: 'Rohu Sarson Mustard',
        description: '2 pcs ,1 Aloo,(1 Plate)',
        price: '₹325.00',
        extras: '',
        image: 'rohu sarso master.jpeg',
      },
      {
        id: 6,
        category: 'Fish Dishes',
        name: 'Rohu Jhol',
        description: '3 pcs (1 Plate)',
        price: '₹325.00',
        extras: '',
        image: 'rohu jhol.jpg',
      },

      // Mutton Dishes (2 items)
      {
        id: 7,
        category: 'Mutton Dishes',
        name: 'Mutton Kosha',
        description: '2 pcs (1 Plate)',
        price: '₹230.00',
        extras: '',
        image: 'Mutton Kosha.jpeg',
      },
      {
        id: 8,
        category: 'Mutton Dishes',
        name: 'Mutton Curry',
        description: 'Spicy Mutton Curry with traditional spices',
        price: '₹350.00',
       
        image: 'mutton curry.jpeg',
      },
      {
        id: 9,
        category: 'Mutton Dishes',
        name: 'Manso Kosha Mutton',
        description: 'Spicy Mutton Kosha with traditional spices 2 Piece(1 Plate)',
        price: '₹340.00',
        
        image: 'manso kosha mutton.jpeg',
      },


      // Chicken Dishes (3 items)
      {
        id: 10,
        category: 'Chicken Dishes',
        name: 'Chicken Thali',
        description: '2 pcs Chicken, Rice, 2 Rotis, Dal, Salad, Sweet Raita, Chutney',
        price: '₹299.00',
        extras: '',
        image: 'chichen thali.webp',
      },
      {
        id: 11,
        category: 'Chicken Dishes',
        name: 'Chicken Angara Mughlai Paratha',
        description: 'Spiced Chicken filling with traditional paratha',
        price: '₹149.00',
        extras: '',
        image: 'muglai paratha.jpeg',
      },
      {
        id: 12,
        category: 'Chicken Dishes',
        name: 'Grilled Chicken',
        description: 'Marinated grilled chicken with herbs',
        price: '₹250.00',
        extras: 'With Vegetables',
        image: 'grilled chicken.jpeg',
      },
      {
        id: 13,
        category: 'Chicken Dishes',
        name: 'Chicken Aloo Jhol',
        description: '2 Piece ,1 Aloo (1 Plate)',
        price: '₹325.00',
       
        image: 'chicken aloo jhol.jpeg',
      },
      {
        id: 14,
        category: 'Chicken Dishes',
        name: 'Egg Mughlai Paratha',
        description: '1 Piece (1 Plate)',
        price: '₹149',
        
        image: 'egg muglai.jpeg',
      },

      // Vegetarian Dishes & Snacks (12 items)
      {
        id: 15,
        category: 'Vegetarian & Snacks',
        name: 'Manso Aloo Jhol',
        description: '2 pcs (1 Plate)',
        price: '₹325.00',
        extras: '',
        image: 'manso aloo jhol.jpeg',
      },
     
     
   
      {
        id: 16,
        category: 'Vegetarian & Snacks',
        name: 'Tawa Roti',
        description: '1 piece',
        price: '₹15.00',
        extras: '',
        image: 'tawa roti.webp',
      },
     
   
    
    
    
   
      {
        id: 17,
        category: 'Vegetarian & Snacks',
        name: 'Steamed Rice',
        description: '1 plate',
        price: '₹135.00',
        extras: '',
        image: 'steamed rice.jpeg',
      },
      {
        id: 18,
        category: 'Vegetarian & Snacks',
        name: 'Plain Rice',
        description: '1 plate',
        price: '₹135.00',
        extras: '',
        image: 'plain rice.jpeg',
      },
     

      // Egg Dishes (4 items)
      {
        id: 19,
        category: 'Egg Dishes',
        name: 'Egg Bhurji',
        description: '5 pcs',
        price: '₹280.00',
        extras: '',
        image: 'egg bhurji.jpeg',
      },
      {
        id: 20,
        category: 'Egg Dishes',
        name: 'Egg Curry',
        description: '2 pcs',
        price: '₹190.00',
        extras: '',
        image: 'egg curry.jpeg',
      },
      {
        id: 21,
        category: 'Egg Dishes',
        name: 'Egg Omelette',
        description: '2 pcs',
        price: '₹125.00',
        extras: '',
        image: 'egg omlette.jpeg',
      },
      {
        id: 22,
        category: 'Egg Dishes',
        name: 'Egg Mughlai Paratha',
        description: 'With traditional paratha',
        price: '₹120.00',
        extras: '',
        image: 'egg muglai p.jpeg',
      },
     
    ],
  };

// Handler for "Call Now" button click
const handleCallNowClick = () => {
  Swal.fire({
    imageUrl: "alert.png",
    title: "Get in Touch to Place Your Order",
    text: "Select your preferred method of contact to proceed with your order.",
    showCancelButton: true,
    confirmButtonText: 'WhatsApp',
    cancelButtonText: 'Call Now',
    customClass: {
      confirmButton: 'swal2-confirm-button',
      cancelButton: 'swal2-cancel-button',
    },
    html: `
      <button id="custom-close" style="position: absolute; top: 10px; right: 10px; background-color: transparent; border: none; font-size: 20px; color: red;">&times;</button>
    `,
    didOpen: () => {
      // Add event listener to the custom close button
      document.getElementById('custom-close').addEventListener('click', () => {
        Swal.close();
      });
    }
  }).then((result) => {
    if (result.isConfirmed) {
      window.open('https://wa.me/910000000000', '_blank');
      Swal.fire({
        title: "Thank you!",
        text: "Thank you for choosing us. We look forward to serving you.",
        icon: "success",
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      window.location.href = 'tel:+910000000000';
      Swal.fire({
        title: "Thank you!",
        text: "Thank you for choosing us. We look forward to serving you.",
        icon: "success",
      });
    }
  });
};
  return (
    <section className="menu-section">
      <div className="menu-header">
        <h5>Food Menu</h5>
        <h2>Our Bengali Menu</h2>
      </div>

      {/* Category Toggles */}
      <div className="menu-categories">
        {menuData.categories.map((category) => (
          <div
            key={category.id}
            className={`menu-category ${
              activeCategory === category.name ? 'active' : ''
            }`}
            onClick={() => setActiveCategory(category.name)}
          >
            {category.icon} {/* Display category icon */}
            <p>{category.name}</p>
          </div>
        ))}
      </div>

      {/* Menu Items List */}
      <div className="menu-items">
        {menuData.items
          .filter((item) => item.category === activeCategory)
          .map((item) => (
            <div className="menu-item" key={item.id}>
              <div className="menu-item-left">
                <img src={item.image} alt={item.name} className="menu-item-img" />
                <div className='ce'>
                  <h4>{item.name}</h4>
                  <p>{item.description}</p>
                  <button className="order-button2" onClick={handleCallNowClick}>Order Now</button>
                </div>
                <div className="menu-item-right">
                <span className="menu-item-price">{item.price}</span>
                <span className="menu-item-extras">{item.extras}</span>
              </div>
              </div>
              
             
            </div>
          ))}
      </div>
    </section>
  );
};

export default Menu;
