import React, { useEffect, useState, useRef } from 'react';
import '../Review/Review.css';

const reviews = [
  {
    id: 1,
    text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde sunt fugiat dolore ipsum id est maxim.',
    name: 'XYZ Name',
    location: 'Location A',
  },
  {
    id: 2,
    text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, t.',
    name: 'XYZ Name',
    location: 'Location B',
  },
  {
    id: 3,
    text: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
    name: 'XYZ Name',
    location: 'Location C',
  },
  {
    id: 4,
    text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum de.',
    name: 'XYZ Name',
    location: 'Location D',
  },
  {
    id: 5,
    text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est.',
    name: 'XYZ Name',
    location: 'Location E',
  },
  {
    id: 6,
    text: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.',
    name: 'XYZ Name',
    location: 'Location F',
  },
  {
    id: 7,
    text: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non.',
    name: 'XYZ Name',
    location: 'Location G',
  },
  {
    id: 8,
    text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.',
    name: 'XYZ Name',
    location: 'Location H',
  },
  {
    id: 9,
    text: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut alr.',
    name: 'XYZ Name',
    location: 'Location I',
  },
];

const Review = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + 3 >= reviews.length ? 0 : prevIndex + 3
      );
    }, 2000); // 2 seconds

    return () => clearInterval(interval);
  }, []);

  const handleMouseDown = (event) => {
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grabbing';
      sliderRef.current.style.scrollSnapType = 'none';
    }
  };

  const handleMouseUp = () => {
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
      sliderRef.current.style.scrollSnapType = 'x mandatory';
    }
  };

  return (
    <section className="review" id="review">
      <h1 className="heading">
        Our <span className="head-span">Customers</span> Says!
      </h1>
      <div
        className="categories-slider2"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {reviews.slice(currentIndex, currentIndex + 3).map((review) => (
          <div key={review.id} className="card3">
            <p>{review.text}</p>
            <h3>{review.name}</h3>
            <p className="location">{review.location}</p>
            <div className="stars">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star-half-alt" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Review;
