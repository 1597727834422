import React, { useState, useEffect } from 'react';
import '../Slider/Slider.css';

const slides = [
    {
      image: 'slidern.jpg',
      heading: 'Welcome to Swad Maya ',
    },
    {
      image: 'slidern2.jpg',
      heading: 'Delicious Food Awaits',
    },
    {
      image: 'slidern3.jpg',
      heading: 'Fast & Reliable Delivery',
    },
  ];

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideCount = slides.length;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === slideCount - 1 ? 0 : prev + 1));
      }, 4000); 
  
      return () => clearInterval(interval);
    }, [slideCount]);
  
    return (
      <div className="slider-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            {index === currentSlide && (
              <div className="overlay">
                <h1 className="slide-heading">{slide.heading}</h1>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default Slider;