import React, { useEffect, useState } from "react";

const About = () => {
  const images = ["hn.png", "hn2.png", "hn3.png"];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const images1 = ["in1.jpg", "in2.jpg", "in3.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <>
      <section className="about-section">
        <div className="about-header">
          <h5>About Us</h5>
          <p>
            Discover our story and explore the journey behind our passion and
            mission. Learn how we began, what drives us, and the values that
            shape every dish we serve.
          </p>
        </div>
        <div className="about-content">
          <div className="left-side">
            <h2 className="history-heading">The History</h2>
            <p>
              With over a decade of culinary excellence, we’ve proudly grown
              into a premier cloud restaurant, bringing exceptional dining
              experiences straight to your doorstep. Our journey started with a
              simple mission: to serve quality with convenience. Today, we’re a
              trusted name in delivering freshly crafted, delicious meals right
              to your home.
            </p>
            <p>
              In addition to reliable delivery, we provide event services to
              make every celebration unforgettable. From birthdays and weddings
              to business meetings, our catering and virtual dining options
              create a restaurant-quality experience tailored for your special
              occasions. Order with us to enjoy unparalleled quality,
              authenticity, and a commitment to making every meal truly
              memorable.
            </p>
          </div>
          <div className="right-side">
            <img
              src={images[currentImageIndex]}
              alt="History"
              className="smooth-image"
            />
          </div>
        </div>
      </section>
      <section className="about-section-aa">
        <div className="about-container-aa">
          {/* Left side image slider */}
          <div className="about-left-aa">
            <div className="image-slider-aa">
              <img
                src={images1[currentIndex]}
                alt={`Slide ${currentIndex}`}
                className="slider-image-aa"
              />
              <div className="arrow-aa left-arrow-aa" onClick={prevImage}>
                &#10094;
              </div>
              <div className="arrow-aa right-arrow-aa" onClick={nextImage}>
                &#10095;
              </div>
            </div>
          </div>

          {/* Right side content */}
          <div className="about-right-aa">
            <p>
              At Swad Maya, we’re proud to be a leading cloud
              restaurant, delivering meals crafted with the finest ingredients
              directly to you. Each dish is thoughtfully prepared by expert
              chefs who skillfully blend traditional flavors with modern
              techniques, creating an unforgettable dining experience that
              satisfies both heart and soul.
            </p>
            <p>
              We’re committed to exceptional service and customer satisfaction,
              whether you’re enjoying our food at home or hosting an event. With
              reliable delivery, we ensure every meal arrives fresh and on time.
              Plus, our catering and party services for special occasions come
              with custom menus designed to impress and elevate your
              celebration. Experience the best in cloud dining with Swad Maya—quality, flavor, and service, right at your doorstep.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
