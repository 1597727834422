import React, { useState, useEffect } from "react";
import '../Northindian/Northindian.css';
import { FaFish, FaDrumstickBite, FaCarrot, FaEgg, FaUtensils } from 'react-icons/fa'; 
import { FaHamburger } from "react-icons/fa";
import { GiChickenOven } from "react-icons/gi";
import { MdDinnerDining } from "react-icons/md";
import { MdFreeBreakfast } from "react-icons/md";
import { CiCircleList } from "react-icons/ci";
import { FaPlateWheat } from "react-icons/fa6";
import Swal from 'sweetalert2';

// Updated Categories Array
const categories = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Snacks',
  'veg',
  'Non Veg',
  'Thali',
  'All'
];

const dishes = [
    // Veg Dishes (IDs 1-25)
    { 
      id: 1, 
      name: 'Aloo Paratha', 
      image: 'alooparatha.png', 
      rating: 3, 
      description: 'Flatbreads made with whole wheat flou, boiled potatoes, spices & herbs.', 
      price: 110, 
      categories: ['Breakfast', 'veg',] 
    },
    { 
      id: 2, 
      name: 'Jhol Paratha', 
      image: 'jholparatha.png', 
      rating: 4, 
      description: 'An unleavened flatbread served with a jhol, or soup', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 3, 
      name: 'Sattu Paratha', 
      image: 'sattuparatha.png', 
      rating: 4, 
      description: 'Flatbread made with a spicy filling of roasted chana flour, or sattu, stuffed into wheat dough.', 
      price: 90, 
      categories: ['Breakfast', 'veg',] 
    },
    { 
      id: 4, 
      name: 'Mix Paratha', 
      image: 'mixparatha.png', 
      rating: 4, 
      description: 'Healthy flatbread made with a variety of vegetables, whole wheat flour, spices, and herbs.', 
      price: 120, 
      categories: ['Breakfast', 'veg',] 
    },
    { 
      id: 5, 
      name: 'Gobhi Paratha', 
      image: 'gobhiparatha.png', 
      rating: 4, 
      description: 'Unleavened whole wheat flatbreads are stuffed with a savour, spiced grated cauliflower filling.', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 6, 
      name: 'Paneer Paratha', 
      image: 'paneerparatha.png', 
      rating: 5, 
      description: 'Unleavened whole wheat flatbreads are stuffed with a savour, spiced grated paneer filling.', 
      price: 149, 
      categories: ['Breakfast', 'veg',] 
    },
    { 
      id: 7, 
      name: 'Aloo Kathal', 
      image: 'alookathal.png', 
      rating: 3, 
      description: 'North Indian dish made with potatoes, jackfruit(Kathal), spices and herbs.', 
      price: 120, 
      categories: [ 'Dinner', 'veg','Lunch'] 
    },
    { 
      id: 8, 
      name: 'Aloo Parwal', 
      image: 'alooparval.png', 
      rating: 4, 
      description: 'It is a dry, slightly tangy & tasty dish made with potatoes, pointed gourd(parwal), spices and herbs', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 9, 
      name: 'Aloo Gobhi', 
      image: 'aloogobhi.png', 
      rating: 4, 
      description: 'Indian vegetarian dish made with potatoes,cauliflower, spices and herbs.', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 10, 
      name: 'Aloo Shimla', 
      image: 'alooshimla.png', 
      rating: 4, 
      description: 'Stir fried dish made of potatoes, capsicum, spices & herbs. 500ml in a plate.', 
      price: 130, 
      categories: ['Lunch', 'veg'] 
    },
    { 
      id: 11, 
      name: 'Matar Paneer', 
      image: 'matarpaneer.png', 
      rating: 5, 
      description: 'Indian cottage cheese and peas cooked in a spicy and flavorsome curry.', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 12, 
      name: 'Shahi Paneer', 
      image: 'shahipaneer.png', 
      rating: 4.5, 
      description: 'Mughlai dish. Paneer is cooked in a creamy gravy made of onions, yogurt, nut and seeds.', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 13, 
      name: 'Palak Paneer', 
      image: 'palakpaneer.png', 
      rating: 4, 
      description: 'Classic curried dish made fresh spinach, onions, spices, paneer and herbs.', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 14, 
      name: 'Paneer Pasanda', 
      image: 'paneerpasanda.png', 
      rating: 4, 
      description: 'Soft paneer cubes filled with aromatic buts & spices fried crisp and served with a luxurious gravy.', 
      price: 199, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 15, 
      name: 'Luchi Torkari', 
      image: 'luchitorkari.png', 
      rating: 5, 
      description: 'Deep-fried flatbread served with a potato curry.', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 16, 
      name: 'Radhaballabhi Torkari', 
      image: 'radhabalvi.png', 
      rating: 5, 
      description: 'Deep-fried pastry with a delicious filling of urad dal spiced with fennel seeds, ginger and green chillies.', 
      price: 140, 
      categories: [ 'Dinner', 'veg',] 
    },
    { 
      id: 17, 
      name: 'Aloo Chaap', 
      image: 'aloochaap.png', 
      rating: 5, 
      description: 'It is made in the same way a batata vada or potato bonda is made. 2 pieces in plate.', 
      price: 40, 
      categories: ['Snacks', 'veg'] 
    },
    { 
      id: 18, 
      name: 'Aloo Bhajia', 
      image: 'aloobhujiya.png', 
      rating: 5, 
      description: '500 ml in a plate. Crispy outside and soft inside Aloo bhajia or potato bhajji.', 
      price: 60, 
      categories: [ 'veg','Dinner'] 
    },
    { 
      id: 19, 
      name: 'Chana Dal Fry', 
      image: 'chanadalfry.png', 
      rating: 4, 
      description: 'Bengal gram is cooked in a spicy onion tomato masala. 500ml in a plate.', 
      price: 120, 
      categories: ['Lunch', 'veg'] 
    },
    { 
      id: 20, 
      name: 'Mix Dal Fry', 
      image: 'mixdalfry.png', 
      rating: 4, 
      description: 'Healthy flatbread made with a variety of vegetables, whole wheat flour, spices, and herbs', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 21, 
      name: 'Jeera Rice', 
      image: 'jeerarice.png', 
      rating: 5, 
      description: 'Rice cooked with cumin seeds & ghee or butter.', 
      price: 120, 
      categories: ['Lunch', 'veg'] 
    },
    { 
      id: 22, 
      name: 'Matar Rice', 
      image: 'matarrice.png', 
      rating: 4, 
      description: 'Rice Peas Pulao made with basmati rice, whole spices, herbs and green peas.', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 23, 
      name: 'Veg Pulao', 
      image: 'vegpulaw.png', 
      rating: 5, 
      description: 'Vegetable pulao is made with veggies like green peas, cauliflower, french beans, carrots, and potatoes.', 
      price: 149, 
      categories: ['Lunch', 'veg'] 
    },
    { 
      id: 24, 
      name: 'Mix Dal Palak', 
      image: 'dalpalak.png', 
      rating: 5, 
      description: 'Healthy spinach dal dish made with spices, lentils and fresh palak.', 
      price: 149, 
      categories: ['Lunch','veg'] 
    },
    { 
      id: 25, 
      name: 'Litti Chokha', 
      image: 'littichokha.png', 
      rating: 5, 
      description: 'Litti is made with wheat flour cake salted and baked. Filled with sattu, spices & served with ghee. Chokha is brinjal barta or mashed potato.', 
      price: 89, 
      categories: ['Lunch','veg'] 
    },
  
    // Thali Dishes (IDs 26-27)
    { 
      id: 26, 
      name: 'Veg Thali (Seasonal)', 
      image: 'vegthali.png', 
      rating: 4, 
      description: 'Complete meal includes variety of dishes, including seasonal vegetables.', 
      price: 299, 
      categories: ['Lunch', 'Dinner', 'Thali', 'veg'] 
    },
    { 
      id: 27, 
      name: 'Veg Thali', 
      image: 'veg.png', 
      rating: 3, 
      description: 'Complete meal includes variety of dishes, including seasonal vegetables', 
      price: 199, 
      categories: ['Lunch', 'Dinner', 'Thali', 'veg'] 
    },
  
    // Non-Veg Dishes (IDs 28-57)
    { 
      id: 28, 
      name: 'Mutton Fry', 
      image: 'muttonfry.png', 
      rating: 4, 
      description: 'Not too spicy nor hot, made with little ghee( clarified butter) brings out flavors of spices and mutton.', 
      price: 449, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 29, 
      name: 'Mutton Tash', 
      image: 'muttonthas.png', 
      rating: 4, 
      description: 'Succulent, juicy and absolutely spicy boneless mutton pieces', 
      price: 449, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 30, 
      name: 'Mutton Rara', 
      image: 'muttonrara.png', 
      rating: 5, 
      description: 'Made with mutton and spices. 500ml 3pcs in a plate.', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 31, 
      name: 'Chicken Gravy', 
      image: 'chickengrevi.png', 
      rating: 4, 
      description: 'It is made with cooked chicken, rice, eggs, soy sauce, and other ingredients. 4 pcs 500ml in a plate.', 
      price: 230, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 32, 
      name: 'Kadai Chicken', 
      image: 'kadahichicken.png', 
      rating: 5, 
      description: 'Chicken is slow cooked with tomatoes and spices in a special iron wok known as Kadai. 4pcs or 500ml.', 
      price: 240, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 33, 
      name: 'Chicken Rara', 
      image: 'chickenrara.png', 
      rating: 4.5, 
      description: 'Chicken pieces are cooked in a spicy and scrumptious chicken mince gravy. 3pcs or 500ml in a plate.', 
      price: 280, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 34, 
      name: 'Chicken Fry', 
      image: 'chickenfry.png', 
      rating: 5, 
      description: 'Chicken is marinated and cooked until tender.', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 35, 
      name: 'Chicken Tash', 
      image: 'chickentash.png', 
      rating: 4.5, 
      description: 'Grilled chicken is prepared with chicken breast. Chicken is matinated with yogurt and spices mixture. Then brushed with oil and grilled.', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
   
    { 
      id: 36, 
      name: 'Rohu Jhal', 
      image: 'rohujhal.png', 
      rating: 4, 
      description: 'Lightly fried Rohu cooked in a sauce rich with flavours of mustard and poppy seeds. 500ml in a plate.', 
      price: 240, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 37, 
      name: 'Tawa Roti', 
      image: 'tawaroti.png', 
      rating: 4.5, 
      description: '2 Piece (250gm) with Butter', 
      price: 25, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 38, 
      name: 'Mutton Thali', 
      image: 'muttonthali.png', 
      rating: 4.5, 
      description: '2 pcs mutton, Chawal, 2 roti, Dal, papad, raita, chatni, mithayi', 
      price: 249, 
      categories: ['Lunch'] 
    },
    { 
      id: 39, 
      name: 'Chicken Thali', 
      image: 'chickenthali.png', 
      rating: 5, 
      description: 'Chicken dish served on a platter with other accompaniments.', 
      price: 299, 
      categories: [ 'Dinner', 'Non Veg', 'Thali'] 
    },
    { 
      id: 40, 
      name: 'Fish Thali', 
      image: 'fishthali.png', 
      rating: 5, 
      description: 'Fish, rice and other dishes served on a platter.', 
      price: 299, 
      categories: ['Lunch', 'Dinner', 'Non Veg', 'Thali'] 
    },
    { 
      id: 41, 
      name: 'Chicken Dam Biryani', 
      image: 'chickendambiryani.png', 
      rating: 5, 
      description: 'Cooked in dum style, this chicken biryani is infused in saffron milk and cooked in spices such as cardamom, clove and cinnamon.', 
      price: 250, 
      categories: ['Lunch', 'Non Veg'] 
    },
    { 
      id: 42, 
      name: 'Maithali Mach Bhaat', 
      image: 'fishcurryy.png', 
      rating: 5, 
      description: 'Fried fish cooked with mustard oil and traditional spices. 1000ml Chahal, 500ml 2pc fish, chatni, pyaaz, mithai', 
      price: 320, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 43, 
      name: 'Egg Chicken Mughlai Paratha', 
      image: 'eggchickenmogliparatha.png', 
      rating: 5, 
      description: 'Bengali flatbreads stuffed with eggs, chicken and other ingredients.', 
      price: 260, 
      categories: ['Breakfast', 'Non Veg'] 
    },
    { 
      id: 44, 
      name: 'Egg Mughlai Paratha', 
      image: 'mogliparatha.png', 
      rating: 4, 
      description: 'Bengali flatbreads stuffed with eggs and other ingredients.', 
      price: 150, 
      categories: ['Breakfast', 'Non Veg',] 
    },
    { 
      id: 45, 
      name: 'Chicken Cutlet', 
      image: 'chickencutlet.png', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Non Veg'] 
    },
    { 
      id: 46, 
      name: 'Chicken Fried Rice', 
      image: 'chickenfryrice.png', 
      rating: 5, 
      description: 'It is made with cooked chicken, rice, eggs, soy sauce, and other ingredients.', 
      price: 139, 
      categories: ['Lunch', 'Non Veg'] 
    },
    { 
      id: 47, 
      name: 'Plain Rice', 
      image: 'plainrice.png', 
      rating: 5, 
      description: 'It is made with rice, oil , salt and lemon is optional.', 
      price: 135, 
      categories: ['Lunch', 'Dinner', 'Veg'] 
    },
    { 
      id: 48, 
      name: 'Fried Rice', 
      image: 'fryrice.png', 
      rating: 5, 
      description: 'Made with a hearty mix of green fresh vegetables, green onions, seasonings, and spices.', 
      price: 139, 
      categories: ['Lunch', 'Dinner', 'Veg'] 
    },
   
    { 
      id: 49, 
      name: 'Classic Chicken', 
      image: 'classicchicken.png', 
      rating: 5, 
      description: 'High quality, hand selected chicken breasts that are oven roasted and delicately browned made up as a home-style chicken.', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 50, 
      name: 'Butter Chicken', 
      image: 'butterchickenn.png', 
      rating: 5, 
      description: 'This creamy butter chicken recipe combines fragrant spices with simple ingredients like onion, butter and tomato sauce for a tasty curry.', 
      price: 230, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 51, 
      name: 'Veg Cutlet', 
      image: 'vegcutlet.png', 
      rating: 3, 
      description: '2 Piece', 
      price: 190, 
      categories: ['Dinner', 'Veg'] 
    },
    { 
      id: 52, 
      name: 'Hara Bhara Kabab', 
      image: 'harabharakabab.png', 
      rating: 5, 
      description: 'Healthy and delicious spinach potato patties filled with Indian flavours. 2 pieces in a plate.', 
      price: 110, 
      categories: ['Snacks', 'veg'] 
    },
    { 
      id: 53, 
      name: 'Chicken Roast', 
      image: 'chickenroast.png', 
      rating: 4, 
      description: 'Fish, rice and other dishes served on a platter', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 54, 
      name: 'Chicken Tikka', 
      image: 'chickentikka.png', 
      rating: 5, 
      description: 'Cooked in dum style, this chicken biryani is infused in saffron milk and cooked in spices such as cardamom, clove and cinnamon. 500ml in a plate.', 
      price: 199, 
      categories: ['Dinner', 'Non Veg'] 
    },
    {
      id: 55,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Rohu Sarson Mustard Fish',
      description: 'Rui fish slices are cooked in mustard-poppy seeds paste with some tomato and green chillies. 500ml in a plate.',
      price: 240,
      rating: 5, 
      image: 'rohu sarso master.jpeg',
    },
    {
      id: 56,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Murgi Aloo Jhol',
      description: 'Bengali light chicken curry with potato. 3 pcs or 500 ml in a plate',
      price: 250,
      rating: 5, 
      image: 'chicken aloo jhol.jpeg',
    },
    {
      id: 57,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Chicken Kosa',
      description: '4 pieces or 500 ml in a plate of chicken Roshan, a spicy, semi-solid gravy with juicy chicken and potatoes.',
      price: 199,
      rating: 5, 
      image: 'chicken kosa.png',
    },
    {
      id: 58,
     categories: ['Dinner', 'veg'] ,
      name: 'Veg Chowmein',
      description: '1000ml in a plate. Veg chowmein is made with stirfried noodles and veggies in a tangy-sweet sauce.',
      price: 100,
      rating: 4.5, 
      image: 'veg chowmein.png',
    },
    {
      id: 59,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Katla Fish',
      description: 'This fish curry is a light curry, supremely nutritious and delicious. 500 ml 4 pcs in a plate.',
      price: 260,
      rating: 4.5, 
      image: 'katla fish.png',
    },
    {
      id: 60,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Fish Curry',
      description: '1 pc fish, chawal, Dal, 2 roti, papa, raita and mithai',
      price: 199,
      rating: 4.3, 
      image: 'fish curry.png',
    },
    {
      id: 61,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Egg Fried Rice',
      description: 'Aromatic egg fried rice with vegetables, eggs and seasonings. 1000 ml in a plate.',
      price: 160,
      rating: 4.5, 
      image: 'egg fried rice.png',
    },
    {
      id: 62,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Chilli Chicken',
      description: '500 ml in a plate of crispy and tender chicken bites tossed in a sweet, spicy and tangy chilli sauce.',
      price: 210,
      rating: 4.5, 
      image: 'chilli chicken.png',
    },
    {
      id: 63,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Egg Curry',
      description: '500 ml in a plate. Boiled eggs simmered in a spicy onion tomato gravy.',
      price: 170,
      rating: 4.5, 
      image: 'egg curry.jpeg',
    },
    {
      id: 64,
     categories: ['Dinner', 'Non Veg'] ,
      name: 'Manso Aloo Jhol',
      description: '3pcs or 500ml in a plate. Bengali mutton curry is a classical comforting light curry prepared with mutton and potato chunks and with limited spices.',
      price: 230,
      rating: 4.5, 
      image: 'manso aloo jhol.png',
    },
    {
      id: 65,
     categories: ['Dinner','Lunch'] ,
      name: 'Steamed Rice',
      description: '1000ml steamed rice in a plate.',
      price: 90,
      rating: 4.5, 
      image: 'steamed rice.jpeg',
    },
    {
      id: 66,
      categories: ['Lunch'] ,
      name: 'Bhojpuri Thali',
      description: '2pcs - 250ml in a plate.',
      price: 120,
      rating: 4, 
      image: 'bhojpuri thali.png',
    },
    { 
      id: 67, 
      name: 'Mutton Thali', 
      image: 'muttonthali.png', 
      rating: 4.5, 
      description: '2 pcs mutton, Chawal, 2 roti, Dal, papad, raita, chatting, pyaaz', 
      price: 249, 
      categories: [ 'Non Veg', 'Thali',] 
    },
    { 
      id: 68, 
      name: 'Kadai Paneer', 
      image: 'kadai paneer.png', 
      rating: 4.5, 
      description: 'Kadai paneer is made by paneer and bell peppers with fresh ground spices. 500ml in a plate.', 
      price: 150, 
      categories: [ 'veg','Lunch'] 
    },
    { 
      id: 69, 
      name: 'Aloo Bhujia', 
      image: 'aloobhujiya.png', 
      rating: 5, 
      description: 'It is made from a tangy combination of boiled potatoes and sautéed onions spiced with chillies and perked up with lemon juice. 500ml in a plate.', 
      price: 90, 
      categories: [ 'veg','Lunch'] 
    },
    { 
      id: 70, 
      name: 'Dahi Baigan', 
      image: 'dahi baigan.png', 
      rating: 5, 
      description: 'Eggplant curry is prepared with deep fried brinjal and spiced and cooked with yoghurt curry. 500ml in a plate.', 
      price: 130, 
      categories: ['veg','Lunch'] 
    },
    { 
      id: 71, 
      name: 'Tikona Ajwain Paratha', 
      image: 'tikona.png', 
      rating: 5, 
      description: 'Flatbreads made with whole wheat flour, spices & herbs. 2pcs in a plate. 1pc in a plate.', 
      price: 20, 
      categories: ['veg','Lunch'] 
    },
    { 
      id: 72, 
      name: 'Arba Bhat', 
      image: 'arba.png', 
      rating: 3.5, 
      description: '1000ml in a plate', 
      price: 90, 
      categories: ['veg','Lunch'] 
    },
    { 
      id: 73, 
      name: 'Egg Thali', 
      image: 'egg thali.png', 
      rating: 4.5, 
      description: 'Chawal, Dal, 2pcs egg in 1 plate.', 
      price: 190, 
      categories: ['veg','Lunch','Thali'] 
    },
    { 
      id: 74, 
      name: 'Dal Paratha', 
      image: 'dal paratha.png', 
      rating: 4, 
      description: 'Flatbreads made with whole wheat flou, boiled potatoes, spices & herbs. 2pcs in a plate.', 
      price: 90, 
      categories: ['veg','Lunch',] 
    },
    { 
      id: 75, 
      name: 'Dal Sagga', 
      image: 'daal sagga.png', 
      rating: 4, 
      description: 'It is made by moong and masoor dal mixed, green chillies, tomato and other spices. 500ml in a plate.', 
      price: 130, 
      categories: ['veg','Lunch',] 
    },
    { 
      id: 76, 
      name: 'Sattu Kachori With Aloo Sabzi', 
      image: 'sattu.png', 
      rating: 4.5, 
      description: 'Sattu Kachori is filled with a flavourful and spiced mixture of roasted gram flour (sattu), aromatic spices, and herbs.', 
      price: 95, 
      categories: ['veg','Lunch',] 
    },
    { 
      id: 77, 
      name: 'French Fries', 
      image: 'french.png', 
      rating: 4.5, 
      description: 'Homemade French fries are crazy delicious. The potatoes fry up perfectly golden and crispy.', 
      price: 80, 
      categories: ['veg','Snacks',] 
    },
    { 
      id: 78, 
      name: 'Sattu Kachori', 
      image: 'sattu.png', 
      rating: 4.5, 
      description: 'Sattu Kachori is filled with a flavourful and spiced mixture of roasted gram flour( sattu) , aromatic spices and herbs. 4 pieces in a plate.', 
      price: 75, 
      categories: ['veg','Snacks',] 
    },
    { 
      id: 79, 
      name: 'Baigan Bhaja', 
      image: 'baigan.png', 
      rating: 4, 
      description: '8pcs 1000ml in a plate', 
      price: 100, 
      categories: ['veg','Snacks',] 
    },
    { 
      id: 80, 
      name: 'Chicken Chaap', 
      image: 'chicken chaap.png', 
      rating: 4, 
      description: 'chicken leg quarters or large pieces of chicken are marinated with hung curd and some spices.', 
      price: 90, 
      categories: ['Snacks','Non Veg'] 
    },
    { 
      id: 81, 
      name: 'Puri Bhaji', 
      image: 'puri bhaji.png', 
      rating: 4.6, 
      description: 'Aloo ki tarkari (potato curry) with 4 pieces puri.', 
      price: 110, 
      categories: ['Snacks','veg'] 
    },
    { 
      id: 82, 
      name: 'Puri Tarkari', 
      image: 'puri tarkari.png', 
      rating: 4.5, 
      description: '4pcs in a plate', 
      price: 90, 
      categories: ['Snacks','veg'] 
    },
    { 
      id: 83, 
      name: 'Veg Chaap', 
      image: 'veg chaap.png', 
      rating: 4.5, 
      description: 'A thick creamy curry prepared with meal maker or soya nuggets, yoghurt and other Indian spices.', 
      price: 80, 
      categories: ['Snacks','veg'] 
    },
    { 
      id: 84, 
      name: 'Egg Bhurji', 
      image: 'egg bhurji.png', 
      rating: 4.5, 
      description: 'Anda Bhurji translates to scrambled eggs (but cooked in Indian style). It is made basically by scrambling beaten eggs with sautéed onions and spices.', 
      price: 70, 
      categories: ['Snacks','Non Veg'] 
    },
    { 
      id: 85, 
      name: 'Sattu Kachori With Chicken', 
      image: 'sattuc.png', 
      rating: 4.5, 
      description: 'Sattu Kachori is filled with a flavourful and spiced mixture of roasted gram flour (sattu), aromatic spices, and herbs.', 
      price: 149, 
      categories: ['Snacks','Non Veg'] 
    },
    { 
      id: 86, 
      name: 'Radha Balbi Tarkari', 
      image: 'radhabalvi.png', 
      rating: 5, 
      description: 'Bengali Urad Kachori 4 Pieces with Aloo tarkari(Potato Curry)', 
      price: 140, 
      categories: ['Snacks'] 
    },
    { 
      id: 87, 
      name: 'Mix Paratha', 
      image: 'mixparatha.png', 
      rating: 4, 
      description: 'Healthy flatbread made with a variety of vegetables, whole wheat flour, spices, and herbs.', 
      price: 100, 
      categories: ['Lunch'] 
    },
    { 
      id: 88, 
      name: 'Gobhi Paratha', 
      image: 'gobhiparatha.png', 
      rating: 4, 
      description: 'Unleavened whole wheat flatbreads are stuffed with a savour, spiced grated cauliflower filling.', 
      price: 110, 
      categories: ['Lunch'] 
    },
    { 
      id: 89, 
      name: 'Paneer Paratha', 
      image: 'paneerparatha.png', 
      rating: 5, 
      description: 'Unleavened whole wheat flatbreads are stuffed with a savour, spiced grated paneer filling. 2pcs in a plate.', 
      price: 130, 
      categories: ['Lunch'] 
    },
    { 
      id: 90, 
      name: 'Egg Chicken Mughlai Paratha', 
      image: 'eggchickenmogliparatha.png', 
      rating: 5, 
      description: 'Bengali flatbreads stuffed with eggs, chicken and other ingredients.', 
      price: 149, 
      categories: ['Lunch'] 
    },
    { 
      id: 91, 
      name: 'Egg Mughlai Paratha', 
      image: 'mogliparatha.png', 
      rating: 4, 
      description: 'Bengali flatbreads stuffed with eggs and other ingredients.', 
      price: 120, 
      categories: ['Lunch'] 
    },
    { 
      id: 92, 
      name: 'Aloo Paratha', 
      image: 'alooparatha.png', 
      rating: 3, 
      description: 'Flatbreads made with whole wheat flou, boiled potatoes, spices & herbs. 2pcs in a plate.', 
      price: 110, 
      categories: ['Lunch'] 
    },
    { 
      id: 93, 
      name: 'Sattu Paratha', 
      image: 'sattuparatha.png', 
      rating: 4, 
      description: 'Flatbread made with a spicy filling of roasted chana flour, or sattu, stuffed into wheat dough. 2pcs in a plate.', 
      price: 90, 
      categories: ['Lunch'] 
    },
    { 
      id: 94, 
      name: 'Chicken Thali', 
      image: 'chickenthali.png', 
      rating: 5, 
      description: 'Chicken dish served on a platter with other accompaniments. 2pcs Chicken, Dal, 2 roti, Chatni, Raita, Papad and Mithayi.', 
      price: 210, 
      categories: ['Lunch'] 
    },


    { 
      id: 95, 
      name: 'Aloo Shimla', 
      image: 'alooshimla.png', 
      rating: 4, 
      description: 'Stir fried dish made of potatoes, capsicum, spices & herbs.', 
      price: 130, 
      categories: [ 'Dinner'] 
    },
    { 
      id: 96, 
      name: 'Chana Dal Fry', 
      image: 'chanadalfry.png', 
      rating: 4, 
      description: 'Bengal gram is cooked in a spicy onion tomato masala.', 
      price: 120, 
      categories: ['Dinner'] 
    },
    { 
      id: 97, 
      name: 'Jeera Rice', 
      image: 'jeerarice.png', 
      rating: 5, 
      description: 'Rice cooked with cumin seeds & ghee or butter. 1000ml in a plate.', 
      price: 100, 
      categories: [ 'Dinner'] 
    },
    { 
      id: 98, 
      name: 'Chicken Dam Biryani', 
      image: 'chickendambiryani.png', 
      rating: 5, 
      description: 'Cooked in dum style, this chicken biryani is infused in saffron milk and cooked in spices such as cardamom, clove and cinnamon. 1000ml in a plate.', 
      price: 190, 
      categories: [ 'Dinner'] 
    },
    { 
      id: 99, 
      name: 'Litti Chokha', 
      image: 'littichokha.png', 
      rating: 5, 
      description: 'Litti is made with wheat flour cake salted and baked. Filled with sattu, spices & served with ghee. Chokha is brinjal barta or mashed potato.', 
      price: 99, 
      categories: [ 'Dinner'] 
    },
    { 
      id: 100, 
      name: 'Veg Pulao', 
      image: 'vegpulaw.png', 
      rating: 5, 
      description: 'Vegetable pulao is made with veggies like green peas, cauliflower, french beans, carrots, and potatoes.', 
      price: 130, 
      categories: ['Dinner'] 
    },
    { 
      id: 101, 
      name: 'Mix Dal Palak', 
      image: 'dalpalak.png', 
      rating: 5, 
      description: 'Healthy spinach dal dish made with spices, lentils and fresh palak.', 
      price: 140, 
      categories: ['Dinner'] 
    },
    { 
      id: 102, 
      name: 'Chicken Fried Rice', 
      image: 'chickenfryrice.png', 
      rating: 5, 
      description: 'It is made with cooked chicken, rice, eggs, soy sauce, and other ingredients. 1000ml in a plate.', 
      price: 180, 
      categories: ['Dinner'] 
    },
    { 
      id: 103, 
      name: 'Rui Sarto Mustard Fish', 
      image: 'Rui Katla.jpeg', 
      rating: 5, 
      description: 'Rui fish slices are cooked in mustard-poppy seeds paste with some tomato and green chillies. 500ml in a plate.', 
      price: 240, 
      categories: ['Dinner'] 
    },
    { 
      id: 104, 
      name: 'Mutton Thali', 
      image: 'muttonthali.png', 
      rating: 4.5, 
      description: '2 pcs mutton, Chawal, 2 roti, Dal, papad, raita, chatting , Pyaz', 
      price: 399, 
      categories: ['Dinner'] 
    },
]


const Northindian = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [filteredDishes, setFilteredDishes] = useState(dishes);

  useEffect(() => {
    if (activeCategory === 'All') {
      setFilteredDishes(dishes);
    } else {
      setFilteredDishes(dishes.filter(dish => dish.categories.includes(activeCategory)));
    }
  }, [activeCategory]);

  // Handler for "Order Now" button click
  const handleCallNowClick = () => {
    Swal.fire({
      imageUrl: "alert.png",
      title: "Get in Touch to Place Your Order",
      text: "Select your preferred method of contact to proceed with your order.",
      showCancelButton: true,
      confirmButtonText: 'WhatsApp',
      cancelButtonText: 'Call Now',
      customClass: {
        confirmButton: 'swal2-confirm-button',
        cancelButton: 'swal2-cancel-button',
      },
      html: `
        <button id="custom-close" style="position: absolute; top: 10px; right: 10px; background-color: transparent; border: none; font-size: 20px; color: red;">&times;</button>
      `,
      didOpen: () => {
        // Add event listener to the custom close button
        document.getElementById('custom-close').addEventListener('click', () => {
          Swal.close();
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('https://api.whatsapp.com/send/?phone=%2B918700458403&text&type=phone_number&app_absent=0', '_blank');
        Swal.fire({
          title: "Thank you!",
          text: "Thank you for choosing us. We look forward to serving you.",
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.location.href = 'tel:+918700458403';
        Swal.fire({
          title: "Thank you!",
          text: "Thank you for choosing us. We look forward to serving you.",
          icon: "success",
        });
      }
    });
  };

  // Function to render category icons based on category name
  const renderCategoryIcon = (categoryName) => {
    switch(categoryName) {
      case 'Breakfast':
        return <MdFreeBreakfast className='c-icon' />;
      case 'Lunch':
        return <FaUtensils className='c-icon' />;
      case 'Dinner':
        return <MdDinnerDining className='c-icon' />;
      case 'Snacks':
        return <FaHamburger className='c-icon' />;
      case 'veg':
        return <FaCarrot className='c-icon' />;
      case 'Non Veg':
        return <GiChickenOven className='c-icon' />;
      case 'Thali':
        return <FaPlateWheat className='c-icon' />;
      case 'All':
        return <CiCircleList className='c-icon' />;
      default:
        return <FaUtensils className='c-icon' />;
    }
  };

  return (
    <>
      <section className="menu-section">
        <div className="menu-header">
          <h5>Food Menu</h5>
          <h2>Our North Indian Menu</h2>
        </div>

        {/* Category Toggles */}
        <div className="menu-categories">
          {categories.map((category, index) => (
            <div
              key={index}
              className={`menu-category ${activeCategory === category ? 'active' : ''}`}
              onClick={() => setActiveCategory(category)}
            >
              {renderCategoryIcon(category)} {/* Display category icon */}
              <p>{category}</p>
            </div>
          ))}
        </div>

        {/* Menu Items List */}
        <div className="menu-items">
          {filteredDishes.length > 0 ? (
            filteredDishes.map((dish) => (
              <div className="menu-item" key={dish.id}>
                <div className="menu-item-left">
                  <img src={dish.image} alt={dish.name} className="menu-item-img" />
                  <div className="ce">
                    <h4>{dish.name}</h4>
                    <p>{dish.description}</p>
                    <p>Rating: {dish.rating} ⭐</p> 
                    <button className="order-button2" onClick={handleCallNowClick}>Order Now</button>
                  </div>
                  <div className="menu-item-right">
                  <span className="menu-item-price">₹{dish.price}</span>
                  {dish.extras && <span className="menu-item-extras">{dish.extras}</span>}
                </div>
                </div>
                
                
              </div>
            ))
          ) : (
            <p>No dishes found for the selected category.</p>
          )}
        </div>
      </section>
    </>
  );
};

export default Northindian;
