import React from 'react';
import '../Provide/Provide.css'; // Import the CSS file


const Provide = () => {
  return (
    <section className="provide-section">
    <div className='txt-div'>
    <h2>We Always Provide You The <span className='provide-span'> Best in Town</span></h2>
    <h5>"Delighting you with top-notch meals, delivered swiftly and fresh from our kitchen to your door"</h5>
    </div>
    <div className="provide-cards">
      <div className="provide-card">
        <i className="fas fa-tag provide-icon"></i>
        <h3>Great Daily Deals</h3>
        <p>Enjoy amazing discounts every day on a wide range of products.</p>
      </div>
      <div className="provide-card">
      <i className="fas fa-motorcycle provide-icon"></i>

        
        <h3>Free Delivery</h3>
        <p>Get your orders delivered to your doorsteps with no additional cost.</p>
      </div>
      <div className="provide-card">
        <i className="fas fa-clock provide-icon"></i>
        <h3>Fast Delivery</h3>
        <p>Experience quick delivery times to ensure your items arrive promptly.</p>
      </div>
    </div>
  </section>
  );
};

export default Provide;
