import React from 'react'
import '../Contactus/Contactus.css'
import Connect from '../Connect/Connect'
const Contactus = () => {
  return (
    <>
 <div className="post-container1">
        <div className="post-image1">
          <img src="swiggy.jpg" alt="Post" />
          <div className="post-overlay1">
            <h1 className="post-heading1">We're Just a Call Away!</h1>
            <p className="post-text1">
            Contact Us for Orders, or Any Questions – We're Ready to Help!
            </p>
          </div>
        </div>
      </div>


      <Connect/>

{/*-
      <section className="information">
      <div className="info-header">
        <h2>Contact Information</h2>
        <p>Get in touch with us</p>
      </div>
      <div className="info-details">
        <div className="info-item">
          <h3>Address</h3>
          <p>123 Main Street, Cityville, Country</p>
        </div>
        <div className="info-item">
          <h3>Phone</h3>
          <p>+9100000000</p>
        </div>
        <div className="info-item">
          <h3>Email</h3>
          <p>contact@example.com</p>
        </div>
      </div>
    </section>
    -*/}
    </>
  )
}

export default Contactus