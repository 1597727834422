import React from "react";
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";
import "../Footer2/Footer2.css";
import { Link } from "react-router-dom";

const Footer2 = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer2">
      <div className="footer-container">
        <div className="about-us">
          <h3>Contact Us</h3>
          <p>
       <strong className="s">Mobile:</strong> +918700458403
          </p> 
          <p>
          <strong className="s">Address:</strong>  SECTOR-7, 
          ARUNODAYA APARTMENT, AMBER HERI, DWARKA, SOUTH WEST , DELHI - 110075
          </p>
          <p> <strong className="s">Email:</strong> care@swadmaya.com</p>
          <div className="find-us">
          <h4>Find Us</h4>
          <div className="social-icons">
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebook />
      </a>
      <a href="https://www.instagram.com/swad.maya/" target="_blank" rel="noopener noreferrer">
        <FaInstagram />
      </a>
    </div>
        </div>
        </div>

        <div className="find-usl">
        <img src="swad.png" className="lof" alt="logo"/>
        </div>

        <div className="links">
          <h3>Links</h3>
          <ul className="footer-linkss">
            <Link className="l" to="/">
              {" "}
              <li>Home</li>
            </Link>
            <Link className="l" to="/northindian">
              {" "}
              <li>North Indian</li>
            </Link>
            <Link className="l" to="/bengali">
              {" "}
              <li>Bengali Food</li>
            </Link>
            <Link className="l" to="/contactus">
              {" "}
              <li>Contact</li>
            </Link>
          </ul>
        </div>
      </div>

      <div className="footer-bottomm">
        <div className="line-decor">
          <span className="line"></span>
          <span className="dot"></span>
          <span className="line"></span>
        </div>
      </div>
      <div className="bottom">
        <p className="all">
          {" "}
          © {currentYear} swadmaya | Powered by{" "}
          <a
            href="https://infogainsoft.com/"
            className="link-info"
            target="_blank"
            rel="noopener noreferrer"
          >
            Infogainsoft
          </a>
        </p>
        <a href="#" className="back-to-top">
          Back To Top
        </a>
      </div>
    </footer>
  );
};

export default Footer2;
