// Banner.js
import React from 'react';
import '../Banner/Banner.css'; // Import the CSS file for styling

const Banner = () => {
  return (
    <div className="banner">
      <div className="ban">
        <img src="of.png" alt="Banner Image" className="banner-img" />
        <div className="banner-text">
        <h3><img src='discount.png' className='offer-img'/> <span className='banner-span'>Discount </span>on Combo Offer</h3>
        <p>Grab your combo offer with amazing discounts. Limited time only!</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
