import React from 'react'
import Menu from '../Menu/Menu'
import Banner from '../Banner/Banner'
import Poster from '../Poster/Poster'
import Categories from '../Categories/Categories'
import Provide from '../Provide/Provide'
import Review from '../Review/Review'
import Slider from '../Slider/Slider'
import Menu2 from '../Aboutus/Menu'
import Gallery from '../Aboutus/Gallery'
import Feedback from '../Aboutus/Feedback'
import Opening from '../Aboutus/Opening'
import About from '../About/About'
import Northindian from '../Northindian/Northindian'

const Home = () => {
  return (
    <>
     <Slider/>
     <About/>
     <Northindian/>
     <Gallery/>
     <Feedback/>
     <Opening/>
    
      
        {/*-
      <Menu/>
      <Review />
       <Banner/>
       <Provide />
         <Poster />  
      <Categories />
      -*/}
     
     
      

      
     
      
      
    </>
  )
}

export default Home