import React, { useState, useEffect } from "react";
import "../Aboutus/Aboutus.css";
import Counter from "../Counter/Counter";
import Services from "../Services/Services";
import Review from "../Review/Review";
import Menu from "./Menu";
import Gallery from "./Gallery";
import Feedback from "./Feedback";
const Aboutus = () => {
  const images = ["hn.png", "h2.png", "h4.png"];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const images1 = ["i1.jpg", "i2.jpg", "i3.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <section className="about-section">
        <div className="about-header">
          <h5>About Us</h5>
          <p>
            Discover our story and learn about our journey and mission.Discover
            our story and learn about our journey and mission.
          </p>
        </div>
        <div className="about-content">
          <div className="left-side">
            <h2 className="history-heading">The History</h2>
            <p>
              We have a rich history of providing the best services to our
              clients for over a decade. Our journey started with a simple
              mission to serve, and today, we have grown into a trusted name in
              the industry.
            </p>
            <p>
              We have a rich history of providing the best services to our
              clients for over a decade. Our journey started with a simple
              mission to serve, and today, we have grown into a trusted name in
              the industry.
            </p>
          </div>
          <div className="right-side">
            <img
              src={images[currentImageIndex]}
              alt="History"
              className="smooth-image"
            />
          </div>
        </div>
      </section>
      <section className="about-section-aa">
        <div className="about-container-aa">
          {/* Left side image slider */}
          <div className="about-left-aa">
            <div className="image-slider-aa">
              <img
                src={images1[currentIndex]}
                alt={`Slide ${currentIndex}`}
                className="slider-image-aa"
              />
              <div className="arrow-aa left-arrow-aa" onClick={prevImage}>
                &#10094;
              </div>
              <div className="arrow-aa right-arrow-aa" onClick={nextImage}>
                &#10095;
              </div>
            </div>
          </div>

          {/* Right side content */}
          <div className="about-right-aa">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
              lacinia justo ut orci scelerisque feugiat. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. In lacinia justo ut orci
              scelerisque feugiat.
            </p>
            <p>
              Curabitur tincidunt, eros nec aliquam efficitur, nisi justo tempor
              lorem, at bibendum eros erat at eros. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. In lacinia justo ut orci scelerisque
              feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              In lacinia justo ut orci scelerisque feugiat.
            </p>
          </div>
        </div>
      </section>
      
     

      <div className="post-container">
        <div className="post-image">
          <img src="post.jpg" alt="Post" />
          <div className="post-overlay">
            <h1 className="post-heading">
              Delicious Delights, Delivered Fast!
            </h1>
            <p className="post-text">
              Savor the Flavors You Love, Right at Your Doorstep!
            </p>
          </div>
        </div>
      </div>

      <div className="abdetail-container">
        <div className="left-ab">
          <img src="imgchef.jpg" alt="Image 1" className="img-1" />
          <img src="cooking.jpg" alt="Image 2" className="img-2" />
        </div>
        <div className="right-ab">
          <h2 className="about-heading">About</h2>
          <h1 className="main-heading">Sudhir Restaurant</h1>
          <p className="description">
            At Sudhir Restaurant, your satisfaction is our priority. We’re
            committed to making every meal an experience to remember, whether
            you're dining in with us or enjoying our food delivered to your
            door. So sit back, relax, and let us bring the taste of Sudhir
            Restaurant to you.
          </p>
          <h4 className="hours">Tue-Sun | 8AM-11PM</h4>
          <h1 className="phone-number">+9100000000</h1>
        </div>
      </div>
      <Counter />
      <Services />
      <Review />
    </>
  );
};

export default Aboutus;
