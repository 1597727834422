import React, { useState, useEffect } from 'react';
import '../Header/Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header-container ${isScrolled ? 'scrolled' : ''}`}>
      <Link to="/" className='no-underline'>
        <div className="logo">
          <img src='swad.png' className='logoimg' alt='logo'/>
          {/* <h3 className='name'>Sudhir Restaurant</h3> */}
        </div>
      </Link>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        <Link to="/northindian">North Indian</Link>
        <Link to="/bengali">Bengali Food</Link>
        <Link to="/contactus">Contact</Link>
        <a href="/menu.pdf" download className="download-link">
          Download Menu
        </a>
      </nav>
      {/* <div className="order-now">Order Now</div> */}
      <div className={`mobile-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
