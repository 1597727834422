import React from 'react';
import '../Poster/Poster.css';
import { FaShippingFast, FaLeaf, FaMotorcycle, FaUtensils } from 'react-icons/fa'; 
import { SiSwiggy } from "react-icons/si";
import { SiZomato } from "react-icons/si";

const Poster = () => {
  return (
    <div className="poster">
    <div className='up-p'>
      <h1 className='up-h'>Locally Produced <span className='up-h-span'> Delivered </span> Direct To Your Door</h1>
      <p className='up-para'>Explore Our Latest Menu Offerings and Discover New Delicacies</p>
     {/*-<button className="shop-now">Shop Now</button>-*/} 
      <img
        src="vposter.png"
        alt="Description of the image"
        className="poster-image"
      />
 <div className="icon-cards">
  <div className="icon-card">
    <FaShippingFast className='ivv' size={40} />  {/* React Icon for Fast Delivery */}
    <p>Fast Delivery</p>
  </div>
  <div className="icon-card">
    <FaLeaf className='ivv' size={40} />  {/* React Icon for Fresh Food */}
    <p>Fresh Food</p>
  </div>
  <div className="icon-card">
    <FaMotorcycle className='ivv' size={40} />  {/* React Icon for Free Delivery */}
    <p>Free Delivery</p>
  </div>
  <div className="icon-card">
    <SiSwiggy className='ivv' size={40} />  {/* React Icon for Swiggy */}
    <p>Available on Swiggy</p>
  </div>
  <div className="icon-card">
    <SiZomato  className='ivv' size={40} />  {/* React Icon for Zomato */}
    <p>Available on Zomato</p>
  </div>
</div>

      </div>
      
    </div>
  );
};

export default Poster;
