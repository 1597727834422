import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import '../Connect/Connect.css';

const Connect = () => {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
    requirements: ''
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    validate(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!Object.values(formErrors).some((error) => error !== '')) {
      setIsSending(true); 
     
      emailjs.send(
        'service_gvm17fl', 
        'template_gxnefrg', 
        formValues,
        'JAs-KO2PXFGVuHdxy' 
      )
      .then((response) => {
        Swal.fire({
          title: 'Email Sent!',
          text: 'Thank you for reaching out to us. We will get back to you shortly.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        setIsSending(false); 
        setFormValues({ name: '', email: '', mobile: '', requirements: '' }); // Reset form
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsSending(false); 
      });
    }
  };

  const validate = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should contain only alphabets.';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address.';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits.';
        break;
      case 'requirements':
        errors.requirements = value.length >= 15 ? '' : 'Requirements should be at least 15 characters long.';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  return (
    <section className="connect">
      <div className="left-con">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d219.33695042141775!2d77.07386126913863!3d28.58380227401336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b1678a63dc7%3A0x8b1ac507d0aa13ca!2sARUNODAYA%20APARTMENTS!5e0!3m2!1sen!2sin!4v1728300081693!5m2!1sen!2sin" 
          width="100%" 
          height="100%" 
          style={{ border: 0 }} 
          allowFullScreen="" 
          loading="lazy">
        </iframe>
      </div>
      <div className="right-con">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              autoComplete='off'
              name="name" 
              value={formValues.name} 
              onChange={handleChange} 
              required 
            />
            {formErrors.name && <p className="error">{formErrors.name}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
               autoComplete='off'
              name="email" 
              value={formValues.email} 
              onChange={handleChange} 
              required 
            />
            {formErrors.email && <p className="error">{formErrors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input 
              type="tel" 
              id="mobile" 
               autoComplete='off'
              name="mobile" 
              value={formValues.mobile} 
              onChange={handleChange} 
              required 
            />
            {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="requirements">Requirements</label>
            <textarea 
              id="requirements" 
              name="requirements" 
               autoComplete='off'
              value={formValues.requirements} 
              onChange={handleChange} 
              rows="4" 
              required 
            ></textarea>
            {formErrors.requirements && <p className="error">{formErrors.requirements}</p>}
          </div>
         
          <button className="send-button" type="submit" disabled={isSending}>
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Connect;
