import React, { useState } from "react";
import "../Bengali/Bengali.css";
import Menu from "../Aboutus/Menu";
const Bengali = () => {
  const images1 = ["b.jpg", "bn2.jpg", "bn3.jpg"];
  const images = ["i1.jpg", "i2.jpg", "i3.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <>
      <div className="post-containerb">
        <div className="post-imageb">
          <img src="postbengali.jpg" alt="Post" />
          <div className="post-overlayb">
            <h1 className="post-headingb">
              Experience the Authentic Delights of Bengali Cuisine at{" "}
              <span className="post-headingb-span">Swad Maya</span>, Delivered
              Fast!
            </h1>
            <p className="post-textb">
              Savor the rich and diverse flavors of Bengal with{" "}
              <span className="post-headingb-span">Swad Maya</span>, right at
              your doorstep!
            </p>
          </div>
        </div>
      </div>
      <div className="full-bengali">
        <section className="about-section-aa">
          <div className="about-container-aa">
            {/* Left side image slider */}
            <div className="about-left-aa">
              <div className="image-slider-aa">
                <img
                  src={images1[currentIndex]}
                  alt={`Slide ${currentIndex}`}
                  className="slider-image-aa"
                />
                <div className="arrow-aa left-arrow-aa" onClick={prevImage}>
                  &#10094;
                </div>
                <div className="arrow-aa right-arrow-aa" onClick={nextImage}>
                  &#10095;
                </div>
              </div>
            </div>

            {/* Right side content */}
            <div className="about-right-aa">
              <p>
                Discover the vibrant flavors of Bengali cuisine from the comfort
                of your home with Swad Maya's cloud restaurant delivery and
                order services! Indulge in the authentic taste of Bengal with
                expertly crafted dishes like 'Machher Jhol,' 'Shorshe Ilish,'
                and more, all delivered fresh to your doorstep.
              </p>
              <p>
                From a comforting 'Kosha Mangsho' to the sweetness of 'Mishti
                Doi,' every meal is a celebration of spice, tradition, and
                culinary passion. Swad Maya brings you an unforgettable Bengali
                dining experience, wherever you are. Order now and enjoy the
                best of Bengal, prepared with love and delivered with care.
                Embrace the essence of Bengali cuisine at Swad Maya – your
                ultimate cloud kitchen for flavors that feel like home!
              </p>
            </div>
          </div>
        </section>
      </div>
      <Menu />
    </>
  );
};

export default Bengali;
