import React, { useEffect, useState } from "react";
import "../Counter/Counter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faConciergeBell,
  faUsers,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

const useCounter = (end, duration) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / end;
    const increment = () => {
      start += 1;
      setCount(start);
      if (start < end) {
        setTimeout(increment, incrementTime);
      }
    };
    increment();
  }, [end, duration]);

  return count;
};

const Counter = () => {
  const countDishes = useCounter(40, 10000);
  const countStaff = useCounter(15, 10000);
  const countDeliveries = useCounter(15000, 10000);
  const countExperience = useCounter(10, 10000);

  return (
    <section id="statistic" className="statistic-section one-page-section">
      <div className="container">
        {/*-<div className="row text-center">
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <FontAwesomeIcon icon={faUtensils} className="fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countDishes}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Dishes</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <FontAwesomeIcon icon={faConciergeBell} className="fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countStaff}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Staff</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <FontAwesomeIcon icon={faTruck} className="fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countDeliveries}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Deliveries</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <FontAwesomeIcon icon={faUsers} className="fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countExperience}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Years of Experience</p>
            </div>
          </div>
        </div>
        -*/}
        <h1 className="c-t">"Food is the ingredient that binds us together"</h1>
      </div>
    </section>
  );
};

export default Counter;
