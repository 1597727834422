import React from 'react';
import '../Categories/Categories.css'; 
import Swal from 'sweetalert2';
const categories = [
  { id: 1, image: 'paneertikka.png', title: 'Paneer Tikka (Veg)' },
  { id: 2, image: 'butterchicken.png', title: 'Butter Chicken (Non-Veg)' },
  { id: 3, image: 'dalmakhni.png', title: 'Dal Makhani (Veg)' },
  { id: 4, image: 'biryaniveg.png', title: 'Biryani (Non-Veg)' },
  { id: 5, image: 'masaladosa.png', title: 'Masala Dosa (Veg)' },
  { id: 6, image: 'gulabjamun.png', title: 'Gulab Jamun (Desserts)' },
  { id: 7, image: 'lassi.png', title: 'Lassi (Beverages)' },
  { id: 8, image: 'samosa.png', title: 'Samosa (Veg Snacks)' },
  { id: 9, image: 'fishcurry.png', title: 'Fish Curry (Non-Veg)' },
  { id: 10, image: 'cholebhature.png', title: 'Chole Bhature (Veg)' },
  { id: 11, image: 'muttonkorma.png', title: 'Mutton Korma (Non-Veg)' },
];

const handleCallNowClick = () => {
  Swal.fire({
    imageUrl: "alert.png",
    title: "Get in Touch to Place Your Order",
    text: "Select your preferred method of contact to proceed with your order.",
    showCancelButton: true,
    confirmButtonText: 'WhatsApp',
    cancelButtonText: 'Call Now',
    customClass: {
      confirmButton: 'swal2-confirm-button',
      cancelButton: 'swal2-cancel-button',
    },
    html: `
      <button id="custom-close" style="position: absolute; top: 10px; right: 10px; background-color: transparent; border: none; font-size: 20px; color: red;">&times;</button>
    `,
    didOpen: () => {
      // Add event listener to the custom close button
      document.getElementById('custom-close').addEventListener('click', () => {
        Swal.close();
      });
    }
  }).then((result) => {
    if (result.isConfirmed) {
      window.open('https://api.whatsapp.com/send/?phone=%2B918700458403&text&type=phone_number&app_absent=0', '_blank');
      Swal.fire({
        title: "Thank you!",
        text: "Thank you for choosing us. We look forward to serving you.",
        icon: "success",
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      window.location.href = 'tel:+918700458403';
      Swal.fire({
        title: "Thank you!",
        text: "Thank you for choosing us. We look forward to serving you.",
        icon: "success",
      });
    }
  });
};


const Categories = () => {
  return (
    <section className="categories">
      <div className="categories-heading">
        <h2>Best Delivered <span className='heading-span'>Categories</span></h2>
        <p>Explore our diverse range of categories and find what suits you best.</p>
      </div>
      <div className="categories-slider">
        {categories.map((category) => (
          <div key={category.id} className="card2">
            <img src={category.image} alt={category.title} />
            <h3 className='tic'>{category.title}</h3>
            <button className="order-button" onClick={handleCallNowClick}>Order Now <i class="fa-solid fa-arrow-right"></i></button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Categories;
