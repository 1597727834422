import React, { useState, useEffect } from 'react';
import '../Aboutus/Feedback.css';

const feedbackData = [
  {
    name: "Rakesh Jha",
    location: "Delhi",
    rating: 5,
    feedback: "Incredible food delivered right to my doorstep!",
    feedbackall: "The quality and taste were outstanding, and the delivery was prompt. Each dish felt freshly prepared, and the flavors were spot on. Swad Maya’s cloud restaurant service is perfect for those who want authentic flavors without leaving home. I highly recommend it for anyone craving top-quality meals at home.", // 40 words
  },
  {
    name: "Sophia Ansari",
    location: "Noida",
    rating: 4,
    feedback: "Amazing flavors and a delightful cloud dining experience!",
    feedbackall: "Swad Maya’s delivery brought the rich flavors of North Indian cuisine right to my door. The dishes were warm, fresh, and full of flavor, making it a memorable dining experience at home. Perfect for busy days when I want something special without the hassle of cooking.", // 40 words
  },
  {
    name: "Anjali Roy",
    location: "Gurgaon",
    rating: 4.5,
    feedback: "Authentic Bengali flavors delivered with care!",
    feedbackall: "Swad Maya’s cloud service brought me the comforting taste of home-cooked Bengali food. The fish curry was perfectly spiced, and the rice was just right. It felt like having a piece of home delivered directly to me. Highly recommended for anyone missing authentic Bengali flavors!", // 40 words
  },
  {
    name: "Rahul Sharma",
    location: "Ghaziabad",
    rating: 4,
    feedback: "Delicious North Indian dishes, especially loved the butter chicken!",
    feedbackall: "The butter chicken was rich and flavorful, just the way I like it. The cloud delivery service was quick, and my food arrived hot and fresh. It’s amazing to enjoy restaurant-quality North Indian food at home with such convenience. Would definitely order again!", // 40 words
  }
];



const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % feedbackData.length);
    }, 5000); // Change slide every 5 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + feedbackData.length) % feedbackData.length);
  };

  return (
    <div className="feedback-section">
     <div className="menu-header2">
        <h5>Happy Customers</h5>
        <h2>Our Customers Love</h2>
      </div>
      <div className="feedback-container">
        {/* Left Side: Static Polygon Image with Circles */}
        <div className="left">
          <div className="polygon-image">
            <img
              src="center.jpg" 
              alt="Polygon"
              className="polygon-img"
            />
            <div className="circular-image">
              <img src="f22.jpg" alt="Circle 1" className="circle-img" /> {/* Replace with actual image URLs */}
              <img src="f3.jpg" alt="Circle 2" className="circle-img" />
              <img src="g4.jpg" alt="Circle 3" className="circle-img" />
            </div>
          </div>
        </div>

        {/* Right Side: Feedback Details */}
        
        <div className="right">
        <div className="customer-info">
            <div className="name-post">
              <h4 className='cu-name'>{feedbackData[currentIndex].name}</h4>
              <p className='cu-p'>{feedbackData[currentIndex].location}</p>
            </div>
            {/* Dots Navigation */}
            <div className="dots">
              {feedbackData.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          </div>
          <div className="rating-stars">
            {"⭐".repeat(feedbackData[currentIndex].rating)}
            <span>({feedbackData[currentIndex].rating}/5)</span>
          </div>
          <h3 className='h3-feed'>{feedbackData[currentIndex].feedback}</h3>
          <p className='p-feed'>"{feedbackData[currentIndex].feedbackall}"</p>
          <hr />
        
        </div>
      </div>

      
    </div>
  );
};

export default Feedback;
