import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { useLocation } from 'react-router-dom';
import Banner from './Components/Banner/Banner';
import Categories from './Components/Categories/Categories';
import Header from './Components/Header/Header';
import Menu from './Components/Menu/Menu';
import Poster from './Components/Poster/Poster';
import Review from './Components/Review/Review';
import Provide from './Components/Provide/Provide';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Aboutus from './Components/Aboutus/Aboutus';
import Contactus from './Components/Contactus/Contactus';
import Chatbox from './Components/Chatbox/Chatbox';
import Bengali from './Components/Bengali/Bengali';
import North from './Components/Northindian/North';
import Footer2 from './Components/Footer2/Footer2';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    const handleContextMenu = (event) => event.preventDefault();
    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J')) || // Ctrl+Shift+I or Ctrl+Shift+J
        (event.ctrlKey && event.key === 'U') // Ctrl+U
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

   
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/poster" element={<Poster />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/bengali" element={<Bengali />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/provide" element={<Provide />} />
        <Route path="/review" element={<Review />} />
        <Route path="/northindian" element={<North />} />
        <Route path="/contactus" element={<Contactus />} />
      </Routes>
      <Chatbox />
      <Footer2 />
    </Router>
  );
}

export default App;
