import React from 'react'
import '../Aboutus/Opening.css'
const Opening = () => {
  return (
    <>
 <div className="opening-hours-section">
      <div className="content-wrapper">
        <div className="heading2">
        <img 
            src="ll.png" 
            alt="Right Decorative Swirl" 
            className="decorative-svg right2" 
          />
          <h2>OPENING HOURS</h2>
          <img 
            src="ll2.png" 
            alt="Right Decorative Swirl" 
            className="decorative-svg right2" 
          />
          <p className="subtext">Call for Reservations</p>
        </div>

        <div className="hourss">
          <div className="days-group">
            <h3>Friday to Sunday</h3>
            <p>10:00 AM</p>
            <p>11:00 PM</p>
          </div>
          <div className="days-group">
            <h3>Monday to Thursday</h3>
            <p>10:00 AM</p>
            <p>10:30 PM</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Opening