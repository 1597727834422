import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'; 
import '../Menu/Menu.css'; 

const Menu = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [isTransitioning, setIsTransitioning] = useState(false);

  const categories = [
    'Breakfast',
    'Lunch',
    'Dinner',
    'Snacks',
    'veg',
    'Non Veg',
    'Thali',
    'All'
  ];
  
  const dishes = [
    // Veg Dishes (IDs 1-25)
    { 
      id: 1, 
      name: 'Aloo Paratha', 
      image: '', 
      rating: 3, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 2, 
      name: 'Jhol Paratha', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 3, 
      name: 'Sattu Paratha', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 4, 
      name: 'Mix Paratha', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 5, 
      name: 'Gobhi Paratha', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 6, 
      name: 'Paneer Paratha', 
      image: '', 
      rating: 5, 
      description: '2 Piece', 
      price: 149, 
      categories: ['Breakfast', 'veg'] 
    },
    { 
      id: 7, 
      name: 'Aloo Kathal', 
      image: '', 
      rating: 3, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 8, 
      name: 'Aloo Parval', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 9, 
      name: 'Aloo Gobhi', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 10, 
      name: 'Aloo Shimla', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 11, 
      name: 'Matar Paneer', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 12, 
      name: 'Shahi Paneer', 
      image: '', 
      rating: 4.5, 
      description: '1 Plate', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 13, 
      name: 'Palak Paneer', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 14, 
      name: 'Paneer Pasanda', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 149, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 15, 
      name: 'Luchi Torkari', 
      image: '', 
      rating: 5, 
      description: '4 Piece', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 16, 
      name: 'Radha Balvi Torkari', 
      image: '', 
      rating: 5, 
      description: '4 Piece', 
      price: 140, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 17, 
      name: 'Aloo Chaap', 
      image: '', 
      rating: 5, 
      description: '2 Piece', 
      price: 40, 
      categories: ['Snacks', 'veg'] 
    },
    { 
      id: 18, 
      name: 'Aloo Bhujiya', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 70, 
      categories: ['Snacks', 'veg'] 
    },
    { 
      id: 19, 
      name: 'Chana Dal Fry', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 20, 
      name: 'Mix Dal Fry', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 21, 
      name: 'Jeera Rice', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 22, 
      name: 'Matar Rice', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 23, 
      name: 'Veg Pulaw', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 140, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 24, 
      name: 'Mix Dal Palak', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 140, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
    { 
      id: 25, 
      name: 'Litti Chokha', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 99, 
      categories: ['Lunch', 'Dinner', 'veg'] 
    },
  
    // Thali Dishes (IDs 26-27)
    { 
      id: 26, 
      name: 'Veg Thali (Seasonal)', 
      image: '', 
      rating: 4, 
      description: 'Rice + 2 Roti, Matar Paneer, Aloo Sabzi, Papad, Salad, Sweets', 
      price: 299, 
      categories: ['Lunch', 'Dinner', 'Thali', 'veg'] 
    },
    { 
      id: 27, 
      name: 'Veg Thali', 
      image: '', 
      rating: 3, 
      description: '1 Plate (According to Season)', 
      price: 199, 
      categories: ['Lunch', 'Dinner', 'Thali', 'veg'] 
    },
  
    // Non-Veg Dishes (IDs 28-57)
    { 
      id: 28, 
      name: 'Mutton Fry', 
      image: '', 
      rating: 4, 
      description: '3 Piece (250gm)', 
      price: 449, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 29, 
      name: 'Mutton Thas', 
      image: '', 
      rating: 4, 
      description: '3 Piece (250gm)', 
      price: 449, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 30, 
      name: 'Mutton Rara', 
      image: '', 
      rating: 5, 
      description: '(250gm)', 
      price: 449, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 31, 
      name: 'Chicken Grevi', 
      image: '', 
      rating: 4, 
      description: '3 Piece (250gm)', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 32, 
      name: 'Kadahi Chicken', 
      image: '', 
      rating: 5, 
      description: '250gm', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 33, 
      name: 'Chicken Rara', 
      image: '', 
      rating: 4.5, 
      description: '250gm', 
      price: 349, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 34, 
      name: 'Chicken Fry', 
      image: '', 
      rating: 5, 
      description: '250gm', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 35, 
      name: 'Chicken Tas', 
      image: '', 
      rating: 4.5, 
      description: '250gm', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 36, 
      name: 'Fish Kari', 
      image: '', 
      rating: 4.5, 
      description: '3 Piece (250gm)', 
      price: 325, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 37, 
      name: 'Rohu Jhal', 
      image: '', 
      rating: 4, 
      description: '3 Piece (250gm)', 
      price: 325, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 38, 
      name: 'Tawa Roti', 
      image: '', 
      rating: 4.5, 
      description: '2 Piece (250gm) with Butter', 
      price: 25, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 39, 
      name: 'Mallan Thali', 
      image: '', 
      rating: 4.5, 
      description: 'With Salad', 
      price: 399, 
      categories: ['Dinner', 'Non Veg', 'Thali'] 
    },
    { 
      id: 40, 
      name: 'Chicken Thali', 
      image: '', 
      rating: 5, 
      description: 'With Salad', 
      price: 299, 
      categories: ['Lunch', 'Dinner', 'Non Veg', 'Thali'] 
    },
    { 
      id: 41, 
      name: 'Fish Thali', 
      image: '', 
      rating: 5, 
      description: 'With Salad', 
      price: 299, 
      categories: ['Lunch', 'Dinner', 'Non Veg', 'Thali'] 
    },
    { 
      id: 42, 
      name: 'Chicken Dam Biryani', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 250, 
      categories: ['Lunch', 'Dinner', 'Non Veg'] 
    },
    { 
      id: 43, 
      name: 'Mathali Mach Balh', 
      image: '', 
      rating: 5, 
      description: 'With Salad', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 44, 
      name: 'Egg Chicken Mogli Paratha', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 149, 
      categories: ['Breakfast', 'Non Veg'] 
    },
    { 
      id: 45, 
      name: 'Egg Mogli Paratha', 
      image: '', 
      rating: 4, 
      description: '1 Plate', 
      price: 120, 
      categories: ['Breakfast', 'Non Veg'] 
    },
    { 
      id: 46, 
      name: 'Chicken Cattles', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 120, 
      categories: ['Snacks', 'Non Veg'] 
    },
    { 
      id: 47, 
      name: 'Chicken Fry Rice', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 139, 
      categories: ['Lunch', 'Dinner', 'Non Veg'] 
    },
    { 
      id: 48, 
      name: 'Plain Rice', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 135, 
      categories: ['Lunch', 'Dinner', 'Non Veg'] 
    },
    { 
      id: 49, 
      name: 'Arva Rice', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 139, 
      categories: ['Lunch', 'Dinner', 'Non Veg'] 
    },
    { 
      id: 50, 
      name: 'Chicken Dam Biryani', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 280, 
      categories: ['Lunch', 'Dinner', 'Non Veg'] 
    },
    { 
      id: 51, 
      name: 'Chicken Egg Mogli Paratha', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 280, 
      categories: ['Breakfast', 'Non Veg'] 
    },
    { 
      id: 52, 
      name: 'Classic Chicken', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 53, 
      name: 'Butter Chicken', 
      image: '', 
      rating: 5, 
      description: '1 Plate', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 54, 
      name: 'Veg Cattles', 
      image: '', 
      rating: 3, 
      description: '2 Piece', 
      price: 190, 
      categories: ['Dinner', 'Non Veg'] // Note: Despite the name, categorized as 'Non Veg' based on original data
    },
    { 
      id: 55, 
      name: 'Hara Bhara Kabab', 
      image: '', 
      rating: 5, 
      description: '2 Piece', 
      price: 110, 
      categories: ['Snacks', 'Non Veg'] // Note: Typically a veg dish, but categorized as 'Non Veg' based on original data
    },
    { 
      id: 56, 
      name: 'Chicken Roast', 
      image: '', 
      rating: 4, 
      description: '2 Piece', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    { 
      id: 57, 
      name: 'Chicken Tikka', 
      image: '', 
      rating: 5, 
      description: '2 Piece', 
      price: 299, 
      categories: ['Dinner', 'Non Veg'] 
    },
    
    // Additional Dishes (if any) can be added here
  ];
  

  const filteredDishes = activeCategory === 'All'
    ? dishes
    : dishes.filter(dish => dish.categories.includes(activeCategory));

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 500); // Match this duration with the CSS transition duration

    return () => clearTimeout(timer);
  }, [activeCategory]);

  // Handler for "Call Now" button click
  const handleCallNowClick = () => {
    Swal.fire({
      imageUrl: "alert.png",
      title: "Get in Touch to Place Your Order",
      text: "Select your preferred method of contact to proceed with your order.",
      showCancelButton: true,
      confirmButtonText: 'WhatsApp',
      cancelButtonText: 'Call Now',
      customClass: {
        confirmButton: 'swal2-confirm-button',
        cancelButton: 'swal2-cancel-button',
      },
      html: `
        <button id="custom-close" style="position: absolute; top: 10px; right: 10px; background-color: transparent; border: none; font-size: 20px; color: red;">&times;</button>
      `,
      didOpen: () => {
        // Add event listener to the custom close button
        document.getElementById('custom-close').addEventListener('click', () => {
          Swal.close();
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('https://api.whatsapp.com/send/?phone=%2B918700458403&text&type=phone_number&app_absent=0', '_blank');
        Swal.fire({
          title: "Thank you!",
          text: "Thank you for choosing us. We look forward to serving you.",
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.location.href = 'tel:+918700458403';
        Swal.fire({
          title: "Thank you!",
          text: "Thank you for choosing us. We look forward to serving you.",
          icon: "success",
        });
      }
    });
  };

  return (
    <div className="menu">
      <div className="menu-headerr"> 
        <h2 className="menu-title">Our Regular <span className='menu-title-span'> Menu</span></h2>
        <p className="menu-description">Explore our regular categories and find what suits you best.</p>
      </div>
      <div className="menu-toggles">
        {categories.map(category => (
          <button
            key={category}
            className={`toggle-button ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
            aria-pressed={activeCategory === category} // Accessibility
          >
            {category}
          </button>
        ))}
      </div>
      <div className={`menu-grid ${isTransitioning ? 'hidden' : ''}`}>
        {filteredDishes.map(dish => (
          <div key={dish.id} className="menu-card">
            <div className="card-image">
              {dish.image ? (
                <img src={dish.image} alt={dish.name} />
              ) : (
                <img src="placeholder.png" alt="Placeholder" /> // Use a placeholder image if none is provided
              )}
            </div>
            <div className="card-content">
              <h3 className="dish-name">{dish.name}</h3>
              <div className="dish-rating" aria-label={`Rating: ${dish.rating} out of 5`}>
                {[...Array(5)].map((_, index) => (
                  <i
                    key={index}
                    className={`fa-star ${index < dish.rating ? 'fas' : 'far'}`}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className='p-d'>{dish.description}</p>
              <div className='pr'>
                <div className="dish-price">₹{dish.price}</div>
                {/* Order Now Button with SweetAlert */}
                <button className="order-button2" onClick={handleCallNowClick}>Order Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
