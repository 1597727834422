import React, { useState } from "react";
import Northindian from "./Northindian";
import "../Northindian/North.css";
const North = () => {
  const images1 = ["n1.jpg", "n2.jpg", "n3.jpg"];
  const images = ["i1.jpg", "i2.jpg", "i3.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <>
      <div className="post-containern">
        <div className="post-imagen">
          <img src="postnorth.jpg" alt="Post" />
          <div className="post-overlayn">
            <h1 className="post-headingn">
              Experience Authentic North Indian Delights at{" "}
              <span className="post-headingb-span">Swad Maya</span>, Delivered
              Fast!
            </h1>
            <p className="post-textn">
              Indulge in the rich, aromatic flavors of North India with{" "}
              <span className="post-headingb-span">Swad Maya</span>, right at
              your doorstep!
            </p>
          </div>
        </div>
      </div>
      <div className="nn">
        <section className="about-section-aaa">
          <div className="about-container-aa">
            {/* Left side image slider */}
            <div className="about-left-aa">
              <div className="image-slider-aa">
                <img
                  src={images1[currentIndex]}
                  alt={`Slide ${currentIndex}`}
                  className="slider-image-aa"
                />
                <div className="arrow-aa left-arrow-aa" onClick={prevImage}>
                  &#10094;
                </div>
                <div className="arrow-aa right-arrow-aa" onClick={nextImage}>
                  &#10095;
                </div>
              </div>
            </div>

            {/* Right side content */}
            <div className="about-right-aa">
              <p>
                Experience the authentic and bold flavors of North Indian
                cuisine with Swad Maya's cloud dining and delivery
                services! Enjoy expertly crafted dishes like the rich 'Butter
                Chicken,' the flavorful 'Paneer Tikka,' and more, all brought
                straight to your door. Each dish is a tribute to North Indian
                culinary heritage, with vibrant spices and deep flavors that
                make every bite memorable.
              </p>
              <p>
                Craving the warmth of 'Rogan Josh' or the comfort of 'Dal
                Makhani'? Sudhir Restaurant promises an unforgettable dining
                experience that captures the heart of North India, right at
                home. Order now for a taste of home-cooked goodness, prepared
                with passion and delivered with care. Rediscover North Indian
                cuisine with Swad Maya – where every meal feels like a
                celebration of tradition!
              </p>
            </div>
          </div>
        </section>

        <Northindian />
      </div>
    </>
  );
};

export default North;
