import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faBriefcase,
  faGlassCheers,
} from "@fortawesome/free-solid-svg-icons";
import "../Services/Services.css";

const Services = () => {
  return (
    <section className="services">
      <div className="services-header">
        <h2>Our <span className="ser-head">Services</span></h2>
        <p>
          From intimate gatherings to grand celebrations, our diverse services
          cater to every need. 
        </p>
      </div>
      <div className="services-cards">
        <div className="card">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faBirthdayCake} className="card-icon" />
          </div>
          <h3>Birthday Party</h3>
          <p>
            Celebrate your special day in style with our customizable birthday
            packages. Enjoy delightful treats, lively decor, and a joyful
            atmosphere tailored just for you.
          </p>
        </div>
        <div className="card">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faBriefcase} className="card-icon" />
          </div>
          <h3>Business Meeting</h3>
          <p>
            Conduct your meetings in a sophisticated environment. We provide all
            the necessary amenities to ensure your business events are
            successful and stress-free.
          </p>
        </div>
        <div className="card">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faGlassCheers} className="card-icon" />
          </div>
          <h3>Wedding Party</h3>
          <p>
            Make your wedding unforgettable with our exquisite
            catering, and attention to every detail. Celebrate love with a touch
            of class and charm.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
